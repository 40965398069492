import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/MdxLayout.tsx";
import SEO from 'src/components/SEO';
import { Tabs, Tab } from '@paljs/ui';
import MdxCard from 'src/components/MdxCard';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Nexus Framework" mdxType="SEO" />
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "nexus"
      }}>{`Nexus`}</h2>
      <p>{`Auto generate CRUD system from your `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`schema.prisma`}</code>{` file.`}</p>
      <p><strong parentName="p">{`CONTENT`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#example-usage"
          }}>{`Example Usage`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#output"
          }}>{`Output`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "#add-paljs-plugin"
          }}>{`Add paljs plugin`}</a></li>
      </ul>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "example-usage"
      }}>{`Example Usage`}</h2>
      <p>{`For more information about Prisma look at they `}<a parentName="p" {...{
          "href": "https://www.prisma.io/docs"
        }}>{`Docs`}</a></p>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`schema.prisma`}</code></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "prisma"
      }}><pre parentName="div" {...{
          "className": "language-prisma"
        }}><code parentName="pre" {...{
            "className": "language-prisma"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`datasource`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` postgresql`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  url      `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`env`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`"DATABASE_URL"`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  provider `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`"postgresql"`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`generator`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` client`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  provider `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`"prisma-client-js"`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id       `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`      `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`autoincrement`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  createdAt`}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` DateTime`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`now`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  email    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`   `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@unique`}</span>{`
  name     `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`
  role     `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Role`}</span>{`     `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token constant"
            }}>{`USER`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  posts    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Post`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`model`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` Post`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  id        `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span>{`        `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@id`}</span>{` `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`autoincrement`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  createdAt `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` DateTime`}</span>{`   `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`now`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  updatedAt `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` DateTime`}</span>{`   `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@updatedAt`}</span>{`
  published `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Boolean`}</span>{`    `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@default`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token boolean"
            }}>{`false`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  title     `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` String`}</span>{`
  author    `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` User`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`      `}<span parentName="code" {...{
              "className": "token annotation"
            }}>{`@relation`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`fields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{`  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`authorId`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` references`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`id`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  authorId  `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{` Int`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`enum`}</span><span parentName="code" {...{
              "className": "token class-name"
            }}>{` Role`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`USER`}</span>{`
  `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`ADMIN`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <p>{`After build your `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`schema.prisma`}</code>{` file all you need to run`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}><span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` prisma generate
`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`>`}</span>{` pal g`}</code></pre></div>
      <p>{`For more information about `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`pal g`}</code>{` command configurations `}<a parentName="p" {...{
          "href": "/cli/generator"
        }}>{`click here`}</a></p>
      <h2 {...{
        "id": "output"
      }}>{`Output`}</h2>
      <p><strong parentName="p">{`Each model will have folder contain 11 files:`}</strong></p>
      <blockquote>
        <p parentName="blockquote">{`NOTE: You can customize all this files and add your logic code inside it just `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`*/type.ts`}</code>{` will rewrite on it.`}</p>
      </blockquote>
      <ul>
        <li parentName="ul">{`User model`}
          <ul parentName="li">
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`User/mutations/createOne.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`User/mutations/deleteOne.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`User/mutations/updateOne.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`User/mutations/upsertOne.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`User/mutations/deleteMany.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`User/mutations/updateMany.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`User/queries/findCount.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`User/queries/findCount.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`User/queries/findMany.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`User/queries/aggregate.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`User/type.ts`}</code></li>
          </ul>
        </li>
        <li parentName="ul">{`Post model`}
          <ul parentName="li">
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`Post/mutations/createOne.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`Post/mutations/deleteOne.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`Post/mutations/updateOne.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`Post/mutations/upsertOne.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`Post/mutations/deleteMany.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`Post/mutations/updateMany.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`Post/queries/findCount.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`Post/queries/findMany.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`Post/queries/findUnique.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`Post/queries/aggregate.ts`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`Post/type.ts`}</code></li>
          </ul>
        </li>
      </ul>
      <p>{`To understand this code structure please look to `}<a parentName="p" {...{
          "href": "https://www.nexusjs.org"
        }}>{`Nexus Docs`}</a></p>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`type.ts`}</code></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` objectType `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'nexus'`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` User `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`objectType`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  nonNullDefaults`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    output`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`true`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    input`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token boolean"
            }}>{`false`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  name`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'User'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token function"
            }}>{`definition`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`int`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'id'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`field`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'createdAt'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'DateTime'`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'email'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`nullable`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'name'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`field`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'role'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Role'`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
    t`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`list`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`field`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'posts'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Post'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      args`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        where`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'PostWhereInput'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        orderBy`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'PostOrderByInput'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        cursor`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'PostWhereUniqueInput'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        take`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Int'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        skip`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Int'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        distinct`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'PostScalarFieldEnum'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token function"
            }}>{`resolve`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`root`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`any`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` root`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`posts
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span></code></pre></div>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`queries`}</code></p>
      <Tabs mdxType="Tabs">
        <Tab title="findUnique.ts" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "ts"
          }}><pre parentName="div" {...{
              "className": "language-ts"
            }}><code parentName="pre" {...{
                "className": "language-ts"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` queryField`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` nonNull `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'nexus'`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`export`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`const`}</span>{` UserFindUniqueQuery `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`queryField`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'findUniqueUser'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'User'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  args`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    where`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserWhereUniqueInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`resolve`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` where `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` select `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`findUnique`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      where`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`...`}</span>{`select`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span></code></pre></div>
        </Tab>
        <Tab title="findMany.ts" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "ts"
          }}><pre parentName="div" {...{
              "className": "language-ts"
            }}><code parentName="pre" {...{
                "className": "language-ts"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` queryField`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` nonNull`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` list `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'nexus'`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`export`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`const`}</span>{` UserFindManyQuery `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`queryField`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'findManyUser'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`list`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'User'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  args`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    where`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserWhereInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    orderBy`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`list`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserOrderByInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    cursor`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserWhereUniqueInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    distinct`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserScalarFieldEnum'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    skip`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Int'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    take`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Int'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`resolve`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` select `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`findMany`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`...`}</span>{`args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`...`}</span>{`select`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span></code></pre></div>
        </Tab>
        <Tab title="findCount.ts" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "ts"
          }}><pre parentName="div" {...{
              "className": "language-ts"
            }}><code parentName="pre" {...{
                "className": "language-ts"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` queryField`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` nonNull`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` list `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'nexus'`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`export`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`const`}</span>{` UserFindCountQuery `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`queryField`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'findManyUserCount'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'Int'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  args`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    where`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserWhereInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    orderBy`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`list`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserOrderByInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    cursor`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserWhereUniqueInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    distinct`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserScalarFieldEnum'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    skip`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Int'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    take`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Int'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`resolve`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` prisma `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`count`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`args `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`as`}</span>{` `}<span parentName="code" {...{
                  "className": "token builtin"
                }}>{`any`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span></code></pre></div>
        </Tab>
        <Tab title="aggregate.ts" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "ts"
          }}><pre parentName="div" {...{
              "className": "language-ts"
            }}><code parentName="pre" {...{
                "className": "language-ts"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` queryField`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` list `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'nexus'`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`export`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`const`}</span>{` UserAggregateQuery `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`queryField`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'aggregateUser'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'AggregateUser'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  args`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    where`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserWhereInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    orderBy`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`list`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserOrderByInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    cursor`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserWhereUniqueInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    distinct`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserScalarFieldEnum'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    skip`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Int'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    take`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'Int'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`resolve`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` select `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`aggregate`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`...`}</span>{`args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`...`}</span>{`select `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`as`}</span>{` `}<span parentName="code" {...{
                  "className": "token builtin"
                }}>{`any`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span></code></pre></div>
        </Tab>
      </Tabs>
      <p><code parentName="p" {...{
          "className": "language-text"
        }}>{`mutations`}</code></p>
      <Tabs mdxType="Tabs">
        <Tab title="createOne.ts" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "ts"
          }}><pre parentName="div" {...{
              "className": "language-ts"
            }}><code parentName="pre" {...{
                "className": "language-ts"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` mutationField`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` nonNull `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'nexus'`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`export`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`const`}</span>{` UserCreateOneMutation `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`mutationField`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'createOneUser'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'User'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  args`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    data`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserCreateInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`resolve`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` data `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` select `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`create`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      data`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`...`}</span>{`select`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span></code></pre></div>
        </Tab>
        <Tab title="deleteOne.ts" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "ts"
          }}><pre parentName="div" {...{
              "className": "language-ts"
            }}><code parentName="pre" {...{
                "className": "language-ts"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` mutationField`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` nonNull `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'nexus'`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`export`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`const`}</span>{` UserDeleteOneMutation `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`mutationField`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'deleteOneUser'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'User'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  args`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    where`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserWhereUniqueInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token function-variable function"
                }}>{`resolve`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`async`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` where `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` select `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`await`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`onDelete`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` model`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'User'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` where `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`delete`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      where`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`...`}</span>{`select`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span></code></pre></div>
        </Tab>
        <Tab title="updateOne.ts" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "ts"
          }}><pre parentName="div" {...{
              "className": "language-ts"
            }}><code parentName="pre" {...{
                "className": "language-ts"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` mutationField`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` nonNull `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'nexus'`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`export`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`const`}</span>{` UserUpdateOneMutation `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`mutationField`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'updateOneUser'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'User'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  args`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    where`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserWhereUniqueInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    data`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserUpdateInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`resolve`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` data`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` where `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` select `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`update`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      where`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
      data`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`...`}</span>{`select`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span></code></pre></div>
        </Tab>
        <Tab title="upsertOne.ts" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "ts"
          }}><pre parentName="div" {...{
              "className": "language-ts"
            }}><code parentName="pre" {...{
                "className": "language-ts"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` mutationField`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` nonNull `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'nexus'`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`export`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`const`}</span>{` UserUpsertOneMutation `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`mutationField`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'upsertOneUser'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'User'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  args`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    where`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserWhereUniqueInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    create`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserCreateInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    update`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserUpdateInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`resolve`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` select `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`upsert`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`...`}</span>{`args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
      `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`...`}</span>{`select`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span></code></pre></div>
        </Tab>
        <Tab title="deleteMany.ts" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "ts"
          }}><pre parentName="div" {...{
              "className": "language-ts"
            }}><code parentName="pre" {...{
                "className": "language-ts"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` mutationField`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` nonNull `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'nexus'`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`export`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`const`}</span>{` UserDeleteManyMutation `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`mutationField`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'deleteManyUser'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'BatchPayload'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  args`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    where`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserWhereInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token function-variable function"
                }}>{`resolve`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`async`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` where `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` prisma `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=>`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`await`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`onDelete`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` model`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'User'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` where `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`deleteMany`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` where `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`as`}</span>{` `}<span parentName="code" {...{
                  "className": "token builtin"
                }}>{`any`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span></code></pre></div>
        </Tab>
        <Tab title="updateMany.ts" mdxType="Tab">
          <div {...{
            "className": "gatsby-highlight",
            "data-language": "ts"
          }}><pre parentName="div" {...{
              "className": "language-ts"
            }}><code parentName="pre" {...{
                "className": "language-ts"
              }}><span parentName="code" {...{
                  "className": "token keyword"
                }}>{`import`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` mutationField`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` nonNull `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`from`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'nexus'`}</span>{`

`}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`export`}</span>{` `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`const`}</span>{` UserUpdateManyMutation `}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`=`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`mutationField`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'updateManyUser'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`type`}</span><span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'BatchPayload'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  args`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    where`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserWhereInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
    data`}<span parentName="code" {...{
                  "className": "token operator"
                }}>{`:`}</span>{` `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`nonNull`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span><span parentName="code" {...{
                  "className": "token string"
                }}>{`'UserUpdateManyMutationInput'`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token function"
                }}>{`resolve`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` args`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{` prisma `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{` `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`{`}</span>{`
    `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span>{`user`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`.`}</span><span parentName="code" {...{
                  "className": "token function"
                }}>{`updateMany`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`(`}</span>{`args `}<span parentName="code" {...{
                  "className": "token keyword"
                }}>{`as`}</span>{` `}<span parentName="code" {...{
                  "className": "token builtin"
                }}>{`any`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span>{`
  `}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`,`}</span>{`
`}<span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`}`}</span><span parentName="code" {...{
                  "className": "token punctuation"
                }}>{`)`}</span></code></pre></div>
        </Tab>
      </Tabs>
    </MdxCard>
    <MdxCard mdxType="MdxCard">
      <h2 {...{
        "id": "add-paljs-plugin"
      }}>{`Add paljs plugin`}</h2>
      <p>{`This plugin adds three parts`}</p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "/plugins/select"
          }}>{`PrismaSelect`}</a>{` plugin to convert `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`info: GraphQLResolveInfo`}</code>{` to select object accepted by `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`prisma client`}</code>{` and add to `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`context`}</code></li>
        <li parentName="ul">{`Admin settings queries and mutations `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`getSchema`}</code>{`, `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`updateField`}</code>{`, `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`updateModel`}</code></li>
        <li parentName="ul">{`All models inputs type`}
          <ul parentName="li">
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`UserWhereInput`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`UserWhereUniqueInput`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`UserOrderByInput`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`UserCreateInput`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`UserUpdateInput`}</code></li>
            <li parentName="ul"><code parentName="li" {...{
                "className": "language-text"
              }}>{`UserUpdateManyMutationInput`}</code></li>
          </ul>
        </li>
      </ul>
      <p><strong parentName="p">{`for include admin settings queries you need to pass in plugin settings`}</strong></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`paljs`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  excludeFields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'password'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token function-variable function"
            }}>{`filterInputs`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`input`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` input`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`fields`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`filter`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`field`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` field`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`name `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`!==`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'passowrd'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span>{` `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`options`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// this options will pass to PrismaSelect class as second arg. https://paljs.com/plugins/select#constructor`}</span>{`
  prismaSelectOptions`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    defaultFields`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`key`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{`
              `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`key`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`boolean`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{`
              `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`|`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`select`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`any`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`key`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`boolean`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
    dmmf`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`DMMF`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Document`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// by default adminSchemaPath is \`adminSettings.json\` you can change it`}</span>{`
  adminSchemaPath`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// include Prisma Admin schema query and mutations`}</span>{`
  includeAdmin`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`boolean`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// send custom dmmf if you have custom generated client path for generate input types`}</span>{`
  dmmf`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`DMMF`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`Document`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// take an array of field names to exclude from any input type`}</span>{`
  `}<span parentName="code" {...{
              "className": "token class-name"
            }}>{`excludeFields`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`string`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// take a function and the input object as arg and return array of fields you want to generate`}</span>{`
  filterInputs`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`input`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`DMMF`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`InputType`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=>`}</span>{` `}<span parentName="code" {...{
              "className": "token constant"
            }}>{`DMMF`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`SchemaArg`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// by default when we create update inputs you will set data like {username: {set: "Ahmed"}} by making this option true you will be able to use it like {username: "Ahmed"} without set.`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// but you will also lose these options for number fields`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// increment: x: Adds x to the current value`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// decrement: x: Subtracts x from the current value`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// multiply: x: Multiplies the current value by x`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// divide: x: Divides the current value by x`}</span>{`
  `}<span parentName="code" {...{
              "className": "token comment"
            }}>{`// set: x: Sets the value to x (equivalent to data: { age: 18 })`}</span>{`
  doNotUseFieldUpdateOperationsInput`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`?`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token builtin"
            }}>{`boolean`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span></code></pre></div>
      <h3 {...{
        "id": "install"
      }}>{`Install`}</h3>
      <p><a parentName="p" {...{
          "href": "https://npmjs.org/package/@paljs/nexus"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/v/@paljs/nexus.svg",
            "alt": "Version"
          }}></img></a>{`
`}<a parentName="p" {...{
          "href": "https://npmjs.org/package/@paljs/nexus"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/dt/@paljs/nexus.svg",
            "alt": "Downloads/week"
          }}></img></a>{`
`}<a parentName="p" {...{
          "href": "https://paljs.com/"
        }}><img parentName="a" {...{
            "src": "https://img.shields.io/npm/l/@paljs/nexus.svg",
            "alt": "License"
          }}></img></a></p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "shell"
      }}><pre parentName="div" {...{
          "className": "language-shell"
        }}><code parentName="pre" {...{
            "className": "language-shell"
          }}><span parentName="code" {...{
              "className": "token function"
            }}>{`yarn`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`add`}</span>{` @paljs/nexus
or
`}<span parentName="code" {...{
              "className": "token function"
            }}>{`npm`}</span>{` i @paljs/nexus`}</code></pre></div>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` makeSchema `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@nexus/schema'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`*`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`as`}</span>{` types `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'./graphql'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`
`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` paljs `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@paljs/nexus'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` schema `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`makeSchema`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  types`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  plugins`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`paljs`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  outputs`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    schema`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` __dirname `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`+`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'/generated/schema.graphql'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    typegen`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` __dirname `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`+`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'/generated/nexus.ts'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  typegenAutoConfig`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    sources`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`[`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        source`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'@prisma/client'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        alias`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'prisma'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
        source`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`require`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`resolve`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'./context'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
        alias`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Context'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`]`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    contextType`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'Context.Context'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`;`}</span></code></pre></div>
      <h4 {...{
        "id": "use"
      }}>{`Use`}</h4>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "ts"
      }}><pre parentName="div" {...{
          "className": "language-ts"
        }}><code parentName="pre" {...{
            "className": "language-ts"
          }}><span parentName="code" {...{
              "className": "token keyword"
            }}>{`import`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` queryField`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` nonNull `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`from`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'nexus'`}</span>{`

`}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`export`}</span>{` `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`const`}</span>{` UserFindUniqueQuery `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`=`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`queryField`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'findUniqueUser'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
  `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`type`}</span><span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token string"
            }}>{`'User'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  args`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    where`}<span parentName="code" {...{
              "className": "token operator"
            }}>{`:`}</span>{` `}<span parentName="code" {...{
              "className": "token function"
            }}>{`nonNull`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token string"
            }}>{`'UserWhereUniqueInput'`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
  `}<span parentName="code" {...{
              "className": "token function"
            }}>{`resolve`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span>{`_parent`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` where `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{` prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` select `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{` `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
    `}<span parentName="code" {...{
              "className": "token keyword"
            }}>{`return`}</span>{` prisma`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span>{`user`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="code" {...{
              "className": "token function"
            }}>{`findUnique`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`(`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`{`}</span>{`
      where`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
      `}<span parentName="code" {...{
              "className": "token operator"
            }}>{`...`}</span>{`select`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
    `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span>{`
  `}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{`
`}<span parentName="code" {...{
              "className": "token punctuation"
            }}>{`}`}</span><span parentName="code" {...{
              "className": "token punctuation"
            }}>{`)`}</span></code></pre></div>
    </MdxCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      